let instance = null;
class SessionHandler {
  static getInstance() {
    if (!instance) instance = new SessionHandler();
    return instance;
  }

  getToken() {
    return this.token;
  }

  setToken(token) {
    this.token = token;
  }
}

export default SessionHandler;