import React, { Component } from "react";
import styled, { ThemeProvider, injectGlobal } from "styled-components";
import { theme, width } from "./utils";
import Meta from "./Meta";

injectGlobal`
  @import '/static/editor.css';

    @font-face {
        font-family:"granville-reg";
        src:url("https://use.typekit.net/af/4d355b/00000000000000003b9b19ba/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/4d355b/00000000000000003b9b19ba/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/4d355b/00000000000000003b9b19ba/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
        font-display:auto;font-style:normal;font-weight:400;
    }

    @font-face {
        font-family:"granville";
        src:url("https://use.typekit.net/af/8f4819/00000000000000003b9b19bb/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/8f4819/00000000000000003b9b19bb/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/8f4819/00000000000000003b9b19bb/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
        font-display:auto;font-style:italic;font-weight:400;
    }

    @font-face {
        font-family:"granville";
        src:url("https://use.typekit.net/af/e4a102/00000000000000003b9b19bc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")format("woff2"),url("https://use.typekit.net/af/e4a102/00000000000000003b9b19bc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/e4a102/00000000000000003b9b19bc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
        font-display:auto;font-style:italic;font-weight:700;
    }

    @font-face {
        font-family:"granville-bold";
        src:url("https://use.typekit.net/af/d81282/00000000000000003b9b19bd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/d81282/00000000000000003b9b19bd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/d81282/00000000000000003b9b19bd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
        font-display:auto;font-style:normal;font-weight:700;
    }

    @font-face {
      font-family: 'Avenir-Roman';
        src:url("/static/fonts/AvenirLTPro-Roman.eot")format("eot"),
        url("/static/fonts/AvenirLTPro-Roman.ttf") format("ttf"),
        url("/static/fonts/AvenirLTPro-Roman.woff") format("woff"),
        url("/static/fonts/AvenirLTPro-Roman.woff2") format("woff2");
        font-weight: normal;
        font-style: normal;
        
    }
        @font-face {
      font-family: 'Avenir-Book';
        src:url("/static/fonts/AvenirLTPro-Book.eot")format("eot"),
        url("/static/fonts/AvenirLTPro-Book.ttf") format("ttf"),
        url("/static/fonts/AvenirLTPro-Book.woff") format("woff"),
        url("/static/fonts/AvenirLTPro-Book.woff2") format("woff2");
        font-weight: normal;
        font-style: normal;
        
    }
    html {
        background-color: ${theme.bgColor};
        box-sizing: border-box;
        font-size: 10px;
        height: 100%;
        width: 100%;
        color: ${theme.primaryColor};
        overflow: auto;
        position: relative;  
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    body {
        background-color: ${theme.bgColor};
        padding: 0;
        margin: 0;
        padding-top: 120px;
        color: ${theme.darkText};
        font-size: 1.5rem;
        font-family: 'Avenir-Book';

        ${width.small`
          padding-top: 70px;
        `}
    }
    h1, h2, h3, h4, h5, h6 {
      color: ${theme.primaryColor};
    }
    a {
        text-decoration: none;
        color: ${theme.primaryColor};
    }
    ul {
        margin-top: 0;
    }
    button {
      outline: none;
      font-family: 'granville';
    }
`;

const Section = styled.div`
  background: ${theme.bgColor};
  padding: 0px 0;
  width: 100%;
  z-index: 0;

  ${width.small`
    padding: 0px;
  `}
`;

const Container = styled.div`
  margin: 0 auto;
  width: 1030px;
  padding: 0 20px;
  z-index: 0;


  ${width.large`
    max-width: 90%;
    margin: 0 auto;
    padding: 0 0px;
  `}

  ${width.med`
    width: 90%;
  `}

  ${width.small`
    padding: 0px;
    max-width: 100%;
  `}
`;

class Page extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Section>
          <Meta />
          <div>{this.props.children}</div>
        </Section>
      </ThemeProvider>
    );
  }
}

export default Page;
