import App, { Container } from "next/app";
import Page from "../components/Page";
import { ApolloProvider } from "react-apollo";
import withData from "../lib/withData";
import { CURRENT_USER_QUERY } from "../components/utils/User";
import Router from "next/router";
import { publicRoutes, onBoardingRoutes } from "../components/utils/constants";
import Intercom from "react-intercom";
import SessionHandler from "../lib/sessionHandler";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import LogRocket from "logrocket";

const IntercomAppId = "";
// const IntercomAppId = 'xu47h0r3';

class MyApp extends App {
  constructor() {
    super(...arguments);
    this.state = {
      hasError: false,
      errorEventId: undefined,
    };
  }

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {
      query: ctx.query,
    };
    try {
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }
      let token;
      let path = ctx.pathname;

      if (!ctx.req) {
        token = localStorage.getItem("token");
      } else {
        if (path === "/signout") {
          console.log("removing cookie");
          destroyCookie(ctx, "token");
          ctx.res.writeHead(302, { Location: "/login" });
          return ctx.res.end();
        }
        token = parseCookies(ctx).token;
      }

      if (token) {
        SessionHandler.getInstance().setToken(token);

        // this opposes the query to the user
        let response = await ctx.apolloClient.query({
          query: CURRENT_USER_QUERY,
        });

        pageProps = {
          ...pageProps,
          IntercomAppId,
          user:
            response.data && response.data.me
              ? {
                  user_id: response.data.me.id,
                  email: response.data.me.email,
                  name: `${response.data.me.firstName} ${response.data.me.lastName}`,
                }
              : null,
        };
      }

      return { pageProps };
    } catch (err) {
      console.log(err);
      return { pageProps };
    }
  }

  async componentDidMount() {
    if (process.browser)
      if (
        window.location.protocol !== "https:" &&
        !window.location.href.includes("localhost")
      )
        window.location.href = `https:${window.location.href.substring(
          window.location.protocol.length
        )}`;
    LogRocket.init("studio-almond/buddhacloud-6bs0u");
    if (
      this.props.pageProps &&
      this.props.pageProps.user &&
      this.props.pageProps.user.user_id
    ) {
      LogRocket.identify(this.props.pageProps.user.user_id, {
        name: this.props.pageProps.user.name,
        email: this.props.pageProps.user.email,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  render() {
    const { Component, apollo, pageProps } = this.props;
    const props = { ...pageProps, apollo };

    return (
      <Container>
        <ApolloProvider client={apollo}>
          <Page>
            {pageProps.user ? (
              <Intercom appID={pageProps.IntercomAppId} {...pageProps.user} />
            ) : null}
            <Component {...pageProps} />
          </Page>
        </ApolloProvider>
      </Container>
    );
  }
}

export default withData(MyApp);
