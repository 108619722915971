import withApollo from "next-with-apollo";
import ApolloClient from "apollo-boost";
import { endpoint, prodEndpoint } from "../config";

import SessionHandler from "./sessionHandler";
let client;
function createClient({ headers }) {
  return (client = new ApolloClient({
    uri: process.env.NODE_ENV === "development" ? endpoint : prodEndpoint,
    request: operation => {
      let token = SessionHandler.getInstance().getToken();
      if (!headers && !token) {
        SessionHandler.getInstance().setToken(localStorage.getItem("token"));
        token = SessionHandler.getInstance().getToken();
      }
      operation.setContext({
        fetchOptions: {
          credentials: "include"
        },
        headers: {
          Authorization: token,
          ["Access-Control-Allow-Origin"]: "*"
          // NOTE: client-side headers is undefined!
        }
      });
    },
    // local data
    clientState: {
      resolvers: {
        Mutation: {}
      },
      defaults: {
        deviceMenuOpen: false
      }
    }
  }));
}

export default withApollo(createClient);
export { client };
