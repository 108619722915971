const libraryCategories = [
  { value: "world", label: "world", field: "category" },
  { value: "compassion", label: "compassion", field: "category" },
  { value: "virtue", label: "virtue", field: "category" },
  { value: "wisdom", label: "wisdom", field: "category" },
  { value: "path", label: "path", field: "category" },
  { value: "mindfulness", label: "mindfulness", field: "category" },
  { value: "self", label: "self", field: "category" },
  { value: "liberation", label: "liberation", field: "category" },
  { value: "buddha", label: "buddha", field: "category" },
  { value: "meditation", label: "meditation", field: "category" },
];

const medCategories = [
  { value: "calm", label: "calm", field: "meditationCategory" },
  { value: "daily life", label: "daily life", field: "meditationCategory" },
  { value: "virtue", label: "virtue", field: "meditationCategory" },
  { value: "mindfulness", label: "mindfulness", field: "meditationCategory" },
  { value: "wisdom", label: "wisdom", field: "meditationCategory" },
  { value: "refuge", label: "refuge", field: "meditationCategory" },
];

const premiumTeaching = [
  { value: "Yes", label: "Yes", field: "premium" },
  { value: "No", label: "No", field: "premium" },
];

const categoryCards = [
  {
    title: "world",
    image: "/static/cards/card-01.jpg",
    subtitle: "The world of the layperson",
  },
  {
    title: "compassion",
    image: "/static/cards/card-02.jpg",
    subtitle: "Kindness, love and non-violence",
  },
  {
    title: "virtue",
    image: "/static/cards/card-03.jpg",
    subtitle: "Psychological discipline and morality",
  },
  {
    title: "wisdom",
    image: "/static/cards/card-04.jpg",
    subtitle: "Buddhist teachings on life",
  },
  {
    title: "mindfulness",
    image: "/static/cards/card-05.jpg",
    subtitle: "Paying close attention",
  },
  {
    title: "path",
    image: "/static/cards/card-06.jpg",
    subtitle: "Concentration and altered states",
  },

  {
    title: "self",
    image: "/static/cards/card-07.jpg",
    subtitle: "Body and personal identity",
  },
  {
    title: "liberation",
    image: "/static/cards/card-08.jpg",
    subtitle: "Nirvana and freedom",
  },
  {
    title: "buddha",
    image: "/static/cards/card-09.jpg",
    subtitle: "Gotama, sage of the Sakyas",
  },
];

const meditationCards = [
  {
    title: "calm",
    image: "/static/cards/card-07.jpg",
    subtitle: "Cultivate a quiet mind",
    isMeditation: true,
  },
  {
    title: "daily life",
    image: "/static/cards/card-08.jpg",
    subtitle: "Wisdom in practice",
    isMeditation: true,
  },
  {
    title: "virtue",
    image: "/static/cards/card-09.jpg",
    subtitle: "Exploring goodness",
    isMeditation: true,
  },
  {
    title: "mindfulness",
    image: "/static/cards/card-09.jpg",
    subtitle: "Attending to the moment",
    isMeditation: true,
  },
  {
    title: "wisdom",
    image: "/static/cards/card-08.jpg",
    subtitle: "Buddhist insights",
    isMeditation: true,
  },
  {
    title: "refuge",
    image: "/static/cards/card-07.jpg",
    subtitle: "Work out your own path",
    isMeditation: true,
  },
];

const currencies = {
  gbp: ["uk"],
  usd: ["us"],
  cad: ["canada"],
  nzd: ["nz"],
  aud: ["aus"],
  eur: ["europe"],
  inr: ["india"],
};

const publicRoutes = ["/", "/login", "/password", "/signup", "/password-reset"];
const onBoardingRoutes = ["/onboarding/welcome", "/onboarding/your-wedding"];

export {
  publicRoutes,
  onBoardingRoutes,
  premiumTeaching,
  libraryCategories,
  categoryCards,
  meditationCards,
  currencies,
  medCategories,
};
